import React from "react";
import validate from "validate.js";

type PropsType = {
    formValidation: validate.ValidateJS;
    fieldName: string;
    fieldValue: string;
}

const InvalidFormInput = ({ formValidation, fieldName, fieldValue }: PropsType) => {
    // @ts-ignore
    if (formValidation && formValidation[fieldName] && fieldValue !== "") {
        // @ts-ignore
        return <div className="small text-danger">{formValidation[fieldName].join()}</div>;
    }

    return null;
};

export default InvalidFormInput;
